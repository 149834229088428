.header {
  display: flex;
  justify-content: space-between;
}

.descriptions {
  margin-bottom: 20px;
}

.buttons {
  margin-top: 0.5rem;
}