#root {
  height: 100%;
  min-height: 100vh;
}

.ant-modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.ant-tag {
  white-space: pre-wrap !important;
}

tr .ant-checkbox .ant-checkbox-inner {
  width: 23px;
  height: 23px;
}
