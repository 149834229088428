.layout {
  min-height: 100%;
}

.content {
  margin: 16px;
}

.page-wrapper {
  min-height: 100%;
  padding: 16px;
  background: #ffffff;
}