.menu {
  overflow: auto;
  height: calc(100vh - 112px);
}

.logo {
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin: 16px;
  background: rgb(255 255 255 / 20%);
  color: #ffffff;
}