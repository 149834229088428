.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  margin-left: auto;
}

.row {
  cursor: pointer;
  max-height: 50px;
}

.archive {
  cursor: pointer;
  background-color: lightgrey;
  max-height: 50px;
}

.resizeColHeader {
  cursor: col-resize;
}

.headerRow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-height: 768px) {
  th {
    padding: 5px !important;
  }
  td {
    padding: 5px !important;
  }
}
